<template>
  <div class="jobs page-container">
    <BannerSwiper height="280px" :swiperList="bannerList" />
    <JobsFilter :initData="filterData" @change="getFilterData"/>
    <JobListWrapper cardVersion="new" :nums="24" pagination :filterData="filterData" style="margin-top:26px" />
  </div>
</template>
<script>
import BannerSwiper from "components/bannerSwiper";
import JobsFilter from "components/jobs/jobsFilter"
import JobListWrapper from "components/jobs/jobListWrapper"

export default {
  name: "PageJobs",
  data: () => ({
    bannerList:[{
      img_url:'https://internal-1257093263.cos.ap-shanghai.myqcloud.com/qz-20220127-company.jpg',
      title:"",
      link:""
    }],
    filterData:{
      curIndex: 0,
      experiences: "",
      educations: "",
      salary_range: "",
      industry: "",
      search_content: "",
      company_industry:"",
      recent:""      
    }
  }),
  created(){
    let filterData = {...this.filterData}
    filterData.search_content = this.$route.params.search_content||""
    filterData.industry  = this.$route.params.industry||""
    this.filterData = filterData
  },
  methods: {
    getFilterData(data){
      this.filterData = data
    }
  },
  computed: {
  },
  watch: {},
  components: { BannerSwiper,JobsFilter,JobListWrapper }
};
</script>
<style lang="scss">
// @import "constants/style/public";
</style>